<template>
  <div>
    <b-modal
      id="taxFormmodal"
      ref="taxFormmodal"
      title="Edit withholding tax"
      ok-only
      ok-title="Save tax"

      @ok="saveData"
    >
      <validation-observer ref="taxForm">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Tax label"
                label-for="taxName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tax label"
                  rules="required"
                >
                  <b-form-input
                    id="taxName"
                    v-model="localTaxData.tax_name"
                    size="lg"
                    placeholder="Enter a label"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-for="(data,index) in localTaxData.tax_rates"
                :key="index"
                :label="data.is_default==1?'Standard rate':'Additional rate'"
                label-for="taxRate"
                label-cols-md="4"
              >
              <validation-provider
              #default="{ errors }"
              name="Tax rate"
              rules="required"
                >
                  <b-row>
                    <b-col
                      md="10"
                      sm="10"
                    >
                      <cleave
                        id="taxRate"
                        v-model="data.rate"
                        class="form-control"
                        :raw="true"
                        :options="$staticParams.taxClaveOptions"
                      />
                    </b-col>
                    <b-col
                      md="2"
                      sm="2"
                    >

                      <b-button
                        v-if="data.is_default!=1"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="localTaxData.tax_rates.splice(index,1)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group

                label-cols-md="4"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"

                  @click="addNewRate"
                >
                  Add rate
                </b-button>
              </b-form-group>
              <b-form-group
                v-if="true==false"
                label="Tax type"
                label-cols-md="4"
              >
                <b-row>
                  <b-button-group size="sm">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="localTaxData.tax_type==1?'dark':'outline-dark'"
                      @click="localTaxData.tax_type=1"
                    >
                      Inclusive
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="localTaxData.tax_type==2?'dark':'outline-dark'"
                      @click="localTaxData.tax_type=2"
                    >
                      Exclusive
                    </b-button>

                  </b-button-group>
                </b-row>
                <b-row>
                  <span
                    v-if="localTaxData.tax_type==2"
                    class="mt-1"
                  >The price of an item excludes tax.</span>
                  <span
                    v-if="localTaxData.tax_type==1"
                    class="mt-1"
                  >The price of an item includes tax.</span>
                </b-row>
              </b-form-group>
              <b-form-group

                v-if="allTaxList.length>0 && true==false"
                label="Accumulative of"
                label-cols-md="4"
              >
                <b-form-checkbox
                  v-for="(data,index) in allTaxList.filter(row=>row.id!=localTaxData.id)"
                  :key="index"

                  v-model="localTaxData.accumulative"
                  class="mt-1"
                  :value="data.id"
                >
                  {{ data.tax_name }}
                </b-form-checkbox>
              </b-form-group>
              <b-form-group

                class="mt-2"

                label-cols-md="4"
              >
                <b-form-checkbox
                  v-model="localTaxData.apply_to_all_items"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  :value="1"
                  :unchecked-value="0"
                >
                  Apply this tax to all items
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BButtonGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import { required, max } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import accountStoreModule from '../../accountStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    Cleave,
    BButton,
    BFormCheckbox,
    BButtonGroup,
  },
  props: {
    taxObject: {
      type: Object,
      default: null,
    },
    allTaxList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      emptyTaxData: {
        id: '',
        tax_name: '',
        tax_rates: [{ rate: 0, is_default: 1 }],
        tax_type: 2,
        apply_to_all_items: 1,
        accumulative: [],

      },
      localTaxData: null,
      // validation rules
      required,
      max,
    }
  },
  watch: {
    taxObject() {
        this.resetLocalData()
    },
    'localTaxData.tax_rates': {
        deep: true,
        handler(newRates) {
            const updatedRates = newRates.map(rate => ({
                ...rate,
                rate: parseFloat(rate.rate) > 100 ? 100 : rate.rate,
            }))
            if (JSON.stringify(updatedRates) !== JSON.stringify(this.localTaxData.tax_rates)) {
                this.localTaxData.tax_rates = updatedRates
            }
        },
    },
},
  created() {
    this.resetLocalData()
  },
  methods: {
    addNewRate() {
      this.localTaxData.tax_rates.push({ rate: 0, is_default: 0 })
    },
    showEditor() {
      this.resetLocalData()
      this.$refs.taxFormmodal.show()
    },
    resetLocalData() {
      if (this.taxObject == null) {
        this.localTaxData = JSON.parse(JSON.stringify(this.emptyTaxData))
        this.localTaxData.id = this.$helpers.generateUuid()
      } else {
        this.localTaxData = JSON.parse(JSON.stringify(this.taxObject))
      }
    },
    saveData(bvModalEvt) {
    bvModalEvt.preventDefault()

    const isValid = this.$refs.taxForm.validate() // Synchronous validation
    if (isValid) {
      this.$emit('dataChanged', this.localTaxData)

      this.$nextTick(() => {
        this.$refs.taxFormmodal.hide()
      })
    }
  },
},
  setup() {
    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return { }
  },
}
</script>
